.meeting-footer{
    background: #202124; 
    height: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
}

.meeting-icons{
    color: white;
    border-radius: 50%;
    background:#3c4043;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.active {
  background-color:   #ea4335;
}

div[disabled]
{
  pointer-events: none;
  opacity: 0.3;
}

.downloadBg{
  background-color: rgb(2, 98, 66);
}

.hidden{
  display:none;
}